import { Button, Container, Typography } from "@material-ui/core"
import BookmarksIcon from "@material-ui/icons/Bookmarks"
import algoliasearch from "algoliasearch/lite"
import React, { useMemo } from "react"
import { Configure, connectHits, InstantSearch } from "react-instantsearch-dom"
import { myContext } from "../../provider"
import Layout from "../components/layout"
import ResultItem from "../components/search/resultItem"
import getFilterString from "../utils/getFilterString"

const LoadItems = ({location}) => {
  const urlParams = new URLSearchParams(location.search)
  
  const loadAccnos = JSON.parse(urlParams.get("accnos"))

  const filterString = getFilterString(loadAccnos ? loadAccnos : [])

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const CustomHits = connectHits(({ hits }) =>
    hits.map(hit => <ResultItem key={hit.ACCNO} hit={hit} />)
  )

  return (
    <Layout>
      <Container maxWidth="md">
        <myContext.Consumer>
          {context => (
            <>
              <Typography variant="h1">Loaded items</Typography>
              <Typography>
                {loadAccnos ? loadAccnos.length : 0} items loaded from URL
              </Typography>
              <Button
                onClick={() =>
                  loadAccnos.map(accNo => context.addBookmarks(accNo))
                }
                endIcon={<BookmarksIcon />}
              >
                Bookmark All
              </Button>
            </>
          )}
        </myContext.Consumer>
        <InstantSearch searchClient={searchClient} indexName={`sarrc_catalog`}>
          <Configure filters={filterString} />
          <CustomHits />
        </InstantSearch>
      </Container>
    </Layout>
  )
} 

export default LoadItems